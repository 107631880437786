/** Dependencies */
import { useState, useEffect } from 'react';
import { Input } from 'smart-webcomponents-react/input';

/** Components */
import DropDownMenu from './DropDownMenu';

/** SCSS */
import './FilterString.scss';

function FilterString( props )
{
  const {
    id,
    filterLabel,
    inputLabel,
    autoCompletedValues,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ currentValue, setCurrentValue ] = useState( initCurrentValues !== null ? initCurrentValues.value : '' );
  const [ filterStringType, setFilterStringType ] = useState( initCurrentValues !== null ? initCurrentValues.filterStringType : 'containsIgnoreCase' );

  useEffect( () => 
  { 
    if( 
      currentValue !== null 
      && currentValue.trim() !== '' 
      && typeof callBackFunction === 'function' 
    )
      callBackFunction( { value: currentValue, filterStringType: filterStringType } );

  }, [ currentValue, filterStringType ]);

  return(
    <div className="string-filter">

      {/* Drop Down Menu */}
      <DropDownMenu 
        id={ id + '-drop-down-menu' }
        label={ filterLabel }
        dropDownMenuValues={[ 
          { label: 'Contient', value: 'containsIgnoreCase' },
          { label: 'Egal à', value: 'equalsIgnoreCase' },
          { label: 'Commence par', value: 'startsWithIgnoreCase' },
          { label: 'Ne contient pas', value: 'doesNotContainIgnoreCase' } 
        ]}
        dropDownMenuSelectionMode='one'
        initCurrentValues={ filterStringType }
        callBackFunction={ setFilterStringType }
      />

      {/* Input */}
      <Input 
        id={ id + '-input' }
        placeholder={ inputLabel }
        dataSource={ autoCompletedValues }
        queryMode={ filterStringType }
        value={ currentValue }
        onChanging={ e => e?.detail?.value !== undefined ? setCurrentValue( e.detail.value ) : null }
        onItemClick={ e => e?.detail?.value !== undefined ? setCurrentValue( e.detail.value ) : null }
        dropDownClassList={ [ id + '-input' ] }
        dropDownWidth={ 307 }
      ></Input>

    </div>
  );
}

export default FilterString;
/** Dependencies */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Input from 'smart-webcomponents-react/input';
import Button from 'smart-webcomponents-react/button';
import RadioButton from 'smart-webcomponents-react/radiobutton';
import moment from 'moment';

/** Component */
import Loader from './../Loader/Loader';

/** Helpers */
import { getUpdateOrAutoValue } from './../../helpers/datas.js';
import { getPicto } from './../../helpers/pictos';
import { callWebservice } from './../../helpers/webservice/webserviceCaller';
import { 
  isNullOrUndefined, 
  sortArrayByObjectField
} from './../../helpers/functions.js';

/** Class */
import wsExpectedUrl from './../../helpers/webservice/wsExpectedUrl.class';
import wsEditoCalendar from './../../helpers/webservice/wsEditoCalendar.class';

/** Services */
import { getItem } from './../../services/LocaleStorage.js';

/** SCSS */
import './EditoCalendar.scss';

function EditoCalendar( props )
{
  /** Get props */
  const {
    keywords,
    loadingParams,
    cancelCallBackFct
  } = props;

  /** Instance Dispatch Hook **/
  const dispatch = useDispatch();

  /** Get user id */
  const userId = getItem( 'userId' );

  /** Define colors */
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ bestClickUrl, setBestClickUrl ] = useState( null );
  const [ expectedUrl, setExpectedUrl ] = useState( null );
  const [ currentValue, setCurrentValue ] = useState( { value: '', type: 'expectedUrl' } );
  const [ editorCalenderRecorded, setEditorCalendarRecorded ] = useState( false );

  /** Load best click url */
  const loadBestClickUrl = () => 
  {
    callWebservice(
      'edito-calendar-loader',
      'edito-calendar-bestClick-loader',
      'gsc-max-clicks-by-keywords',
      dispatch,
      loadingParams.clientID,
      {
        period: {
          startDate: moment().subtract( 1, 'year' ).format('YYYYMMDD'),
          endDate: moment().format('YYYYMMDD')
        },
        where: {
          keywords: Array.isArray( keywords ) ? keywords : [ keywords ],
          locations: [ loadingParams.location.split( '|' )[0] ],
          devices: [ loadingParams.device ]
        }
      },
      { function: 'setBestClickUrl' }
    );
  }

  /** Load expected url */
  const loadExpectedUrl = () => 
  {
    if( 
      typeof keywords === 'string' 
      || Array.isArray( keywords )
    ){
      // update value in DB
      new wsExpectedUrl(
        'edito-calendar-loader',
        loadingParams.device,
        loadingParams.location,
        dispatch,
        loadingParams.clientID
      ).getExpectedUrl( 
        keywords,
        'setExpectedUrl'          
      );
    }
  }

  /** Valid form */
  const validForm = () => 
  {
    if(
      !isNullOrUndefined( currentValue?.value )
      && !isNullOrUndefined( currentValue.type !== null )
      && currentValue.type.trim() !== ''
    ){
      // update value in DB
      new wsEditoCalendar(
        'edito-calendar-loader',
        loadingParams.location,
        dispatch,
        loadingParams.clientID
      ).add( 
        keywords,
        currentValue.value,
        userId,
        'validForm'     
      );
    }
  }

  /** Load datas */
  useEffect( () => 
  {
    // load best click url
    loadBestClickUrl();

    // load others urls
    loadExpectedUrl();

  }, []);

  /** Init current value with expected url */
  useEffect( () => 
  {
    if( !isNullOrUndefined( expectedUrl ) ) 
      setCurrentValue({ 
        ...currentValue, 
        value: expectedUrl.url, 
        type: 'expectedUrl' 
      })

  }, [ expectedUrl ]);

  return(
    <div className="edito-calendar-container">

      {/* Loader */}
      <Loader 
        loaderID='edito-calendar-loader'
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setExpectedUrl: results => setExpectedUrl( 
            typeof keywords === 'string'
            && !isNullOrUndefined( results[keywords] ) ?
              {
                url: getUpdateOrAutoValue( results[keywords][0], 'expectedUrl' ),
                type: results[keywords][0]['type']
              }
              : null 
          ),
          setBestClickUrl: results => setBestClickUrl( 
            (
              typeof keywords === 'string'
              && !isNullOrUndefined( results[keywords] ) 
              && results[keywords].filter( elem => elem.maxClicks > 0 ).length > 0
            ) ? 
              results[keywords][0].url
            : 
            (
              Array.isArray( keywords )
              && keywords.length > 0 
              && keywords.map( keyword => 
                !isNullOrUndefined( results[keyword] ) && results[keyword].filter( elem => elem.maxClicks > 0 ).length > 0 ? results[keyword] : [] 
              ).flat().length > 0 
            ) ? 
              sortArrayByObjectField( 
                keywords.map( keyword => 
                  !isNullOrUndefined( results[keyword] ) 
                  && results[keyword].filter( elem => elem.maxClicks > 0 ).length > 0 ? 
                    results[keyword] 
                    : [] 
                ).flat(), 
                'maxClicks',
                'number'
              )[0].url
            : null
          ),
          validForm: () => setEditorCalendarRecorded( true )
        }}
      />

      {/* Confirm window after event have been recorded */}
      {        
        editorCalenderRecorded ?
          <div className='editor-calendar-recorded'>
            <h3>Votre sujet a été ajouté au calendrier édito</h3>
            <div className='confirm-container'>
              <Button 
                className='flat empty'
                onClick={ () => typeof cancelCallBackFct === 'function' ? cancelCallBackFct() : null }
              >Fermer</Button>
            </div>
          </div>
          : void( 0 )
      }
      
      {/* Radio Buttons container */}
      <div className='buttons-container'>

        {/* Expected Url radio button */}
        {
          !isNullOrUndefined( expectedUrl ) ?
            <RadioButton 
              innerHTML="URL attendue"
              checked={ currentValue.type === 'expectedUrl' ? true : false }
              onClick={ () => setCurrentValue({ 
                ...currentValue, 
                value: expectedUrl.url, 
                type: 'expectedUrl' 
              })} 
            />
            : null
        }

        {/* Best click Url radio button */}
        {
          !isNullOrUndefined( bestClickUrl ) ?
            <RadioButton 
              innerHTML="Meilleure URL" 
              checked={ currentValue.type === 'bestClickUrl' ? true : false }
              onClick={ () => setCurrentValue({ 
                ...currentValue, 
                value: bestClickUrl, 
                type: 'bestClickUrl' 
              })} 
            />
            : null
        }

        {/* New Url radio button */}
        <RadioButton 
          innerHTML="Nouvelle URL" 
          checked={ currentValue.type === 'newUrl' ? true : false }
          onClick={ () => setCurrentValue({ 
            ...currentValue, 
            value: '', 
            type: 'newUrl' 
          })} 
        />

      </div>

      {/* Input url container */}
      <div className='input-url-container'>
        <Input 
          value={ currentValue.value }
          disabled
        />
        {
          currentValue.type !== 'newUrl' ?
            <button 
              onClick={ () => !isNullOrUndefined( currentValue?.value ) && currentValue.value.trim() !== '' ? 
                window.open( currentValue.value, '_blank' ) 
                : void( 0 ) 
              }
              disabled={ isNullOrUndefined( currentValue?.value ) || currentValue.value.trim() === '' ? true : false }
            >{ getPicto( 'ArrowSquareOut', { size: '1.5rem', fill: grey2Color } ) }</button>
            : null
        }
      </div>

      {/* Confirm container */}
      <div className='confirm-container'>
        <Button 
          className='flat empty'
          onClick={ () => typeof cancelCallBackFct === 'function' ? cancelCallBackFct() : null }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ validForm }  
        >Enregistrer</Button>
      </div>

    </div>
  );
}

export default EditoCalendar;
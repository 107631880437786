/** Dependencies */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useParams } from "react-router-dom";
import Button from 'smart-webcomponents-react/button';

/** Components */
import RangeFilterWidget from './../Widgets/FiltersWindowWidgets/Range';
import KeywordsFilterWidget from './../Widgets/FiltersWindowWidgets/Keywords';
import SnippetsFilterWidget from './../Widgets/FiltersWindowWidgets/Snippets';
import DropDownMenuMultiFilterWidget from './../Widgets/FiltersWindowWidgets/DropDownMenuMulti';
import UrlFilterWidget from './../Widgets/FiltersWindowWidgets/Url';
import FiltersGroups from './../FiltersGroups/FiltersGroups';
import Categories from './../Widgets/FiltersWindowWidgets/Categories';
import Loader from './../Loader/Loader';

/** Helpers */
import { isNullOrUndefined, updateURLSearchParams } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos';
import { getPredefinedFilterRangesValues } from './../../helpers/datas';
import { getInstanceDatas } from './../../helpers/instance';
import { getDateFromPeriod } from './../../helpers/dates.js';
import { updateFilterDatas } from './../../helpers/filters.js';
import { callWebservice } from './../../helpers/webservice/webserviceCaller';

/** SCSS */
import './FiltersWindow.scss';

function FiltersWindow( props )
{
  const {
    openCloseToggler,
    minMaxValues,
    autoCompletedLabelsValues,
    autoCompletedRankedUrlsValues,
    autoCompletedExpectedUrlsValues,
    dropDownMenuKeywordsGroups,
    filtersToDisplay
  } = props;

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get url params */
  const { clientID, location, device } = useParams();

  // get city from data user
  const city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ currentFilters, setCurrentFilters ] = useState( searchParams.get( 'filters' ) !== null ? JSON.parse( searchParams.get( 'filters' ) ) : [] );
  const [ categories, setCategories ] = useState( null );
  const [ reloadKey, setReloadKey ] = useState( 0 );

  // Define colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  // Define min / max values
  const minVolume = ( minMaxValues !== null && minMaxValues['volume.value'] !== null && minMaxValues['volume.value']?.min !== undefined ) ? minMaxValues['volume.value'].min : null;
  const maxVolume = ( minMaxValues !== null && minMaxValues['volume.value'] !== null  && minMaxValues['volume.value']?.max !== undefined ) ? minMaxValues['volume.value'].max : null;
  const minClicks = ( minMaxValues !== null && minMaxValues['gsc.clicks.value'] !== null  && minMaxValues['gsc.clicks.value']?.min !== undefined ) ? minMaxValues['gsc.clicks.value'].min : null;
  const maxClicks = ( minMaxValues !== null && minMaxValues['gsc.clicks.value'] !== null  && minMaxValues['gsc.clicks.value']?.max !== undefined ) ? minMaxValues['gsc.clicks.value'].max : null;
  const minImpressions = ( minMaxValues !== null && minMaxValues['gsc.impressions.value'] !== null  && minMaxValues['gsc.impressions.value']?.min !== undefined ) ? minMaxValues['gsc.impressions.value'].min : null;
  const maxImpressions = ( minMaxValues !== null && minMaxValues['gsc.impressions.value'] !== null  && minMaxValues['gsc.impressions.value']?.max !== undefined ) ? minMaxValues['gsc.impressions.value'].max : null;
  const minCTR = ( minMaxValues !== null && minMaxValues['gsc.ctr.value'] !== null  && minMaxValues['gsc.ctr.value']?.min !== undefined ) ? Math.floor( minMaxValues['gsc.ctr.value'].min * 100 ) : null;
  const maxCTR = ( minMaxValues !== null && minMaxValues['gsc.ctr.value'] !== null  && minMaxValues['gsc.ctr.value']?.max !== undefined ) ? Math.ceil( minMaxValues['gsc.ctr.value'].max * 100 ) : null;
  const minRankedRate = ( minMaxValues !== null && minMaxValues['serp.rate.value'] !== null  && minMaxValues['serp.rate.value']?.min !== undefined && minMaxValues['serp.rate.value']?.min !== -1 ) ? Math.floor( minMaxValues['serp.rate.value'].min * 100 ) : null;
  const maxRankedRate = ( minMaxValues !== null && minMaxValues['serp.rate.value'] !== null  && minMaxValues['serp.rate.value']?.max !== undefined && minMaxValues['serp.rate.value']?.max !== -1 ) ? Math.ceil( minMaxValues['serp.rate.value'].max * 100 ) : null;
  const minPosition = ( minMaxValues !== null && minMaxValues['serp.position.value'] !== null  && minMaxValues['serp.position.value']?.min !== undefined && minMaxValues['serp.position.value'].min !== -1 ) ? minMaxValues['serp.position.value'].min : null;

  // Define predefined values for volume
  const predefinedVolumeRanges = getPredefinedFilterRangesValues( 
    [ '0|10', '10|100', '100|1000', '1000|10000', '10000|100000', '100000|more'], 
    minVolume,
    maxVolume 
  );

  // Define predefined values for clicks
  const predefinedClicksRanges = getPredefinedFilterRangesValues( 
    [ '0|10', '10|100', '100|1000', '1000|10000', '10000|more' ], 
    minClicks,
    maxClicks
  );

  // Define predefined values for impressions
  const predefinedImpressionsRanges = getPredefinedFilterRangesValues( 
    [ '0|10', '10|100', '100|1000', '1000|10000', '10000|100000', '100000|more'], 
    minImpressions,
    maxImpressions
  );

  // Define predefined values for ctr
  const predefinedCTRRanges = getPredefinedFilterRangesValues( 
    [ '0|1', '2|5', '5|10', '10|more' ], 
    minCTR,
    maxCTR
  );

  // Define predefined values for ctr
  const predefinedRankedRateRanges = getPredefinedFilterRangesValues( 
    [ '0|1', '2|5', '5|10', '10|more' ], 
    minRankedRate,
    maxRankedRate
  );

  // Define predefined values for positions
  const predefinedPositionRanges = getPredefinedFilterRangesValues( 
    [ '0|3', '4|10', '11|20', '21|100', '100|more' ], 
    minPosition,
    101
  );

  /**
   * Update filter datas for url
   * @param {Object} filterTypes 
   * @param {String} filterName 
   * @param {String} dataField 
   * @param {String} filterLabel 
   * @param {Object} filterDatas 
   * @returns Array
   */
  const updateFilter = ( filterTypes, filterName, dataField, filterLabel, filterDatas ) => 
    setCurrentFilters( updateFilterDatas( currentFilters, filterTypes, filterName, dataField, filterLabel, filterDatas ) );

  const validForm = () => 
  {
    // update url search params
    updateURLSearchParams( 
      { 
        filters: currentFilters.length > 0 ?
          JSON.stringify( currentFilters ) 
          : null
      }, 
      [ searchParams, setSearchParams ] 
    );

    // close window
    openCloseToggler();
  }

  const clearFilters = () => 
  {
    // update url search params
    updateURLSearchParams( { filters: null }, [ searchParams, setSearchParams ] );

    // close window
    openCloseToggler();
  }

  const updateFiltersFromGroup = values => 
  {
    // set current filters with group values
    setCurrentFilters( values );

    // reload filters components with updating keys with random number
    setReloadKey( Math.ceil( Math.random() * 100 ) );
  }

  /** Load categories */
  useEffect(() => 
  { 
    // load data source
    callWebservice(
      'filters-window-loader',
      'filters-window-loader',
      'get-categories',
      dispatch,
      clientID,
      {
        context: 'keywords',
        period: getDateFromPeriod( currentPeriod, null, 'YYYYMMDD' ),
        where: {
          locations: [ location + '|' + city ],
          devices: [ device ]
        }
      },
      {
        function: 'setCategories'
      }
    );

  }, []);

  /** Set current and compare period from searchParams */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );
      
  }, [ searchParams ]);

  return(
    <Fragment>
      <div className='filters-container'>

        {/* Loader */}
        <Loader 
          loaderID='filters-window-loader'
          loaderStyle={{
            width:'25', 
            stroke: blueColor, 
            viewBox:'-2 -2 42 42'
          }}
          callBackFcts={{
            setCategories: results => setCategories( results )
          }}
        />

        <div className='filters-widgets-container'>

          {/* Range Volume */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'volume' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-volume' }
              id="filter-range-volume"
              pictoTitle={ getPicto( 'MagnifyingGlass', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Volume"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'volume' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'volume' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range',
                  variation: 'variation'
                },
                'volume',
                'volume',
                'Volume',
                filterDatas 
              )}
              sliderMinValue={ minVolume !== null ? minVolume : 0 }
              sliderMaxValue={ maxVolume !== null ? maxVolume : 100 }
              dropDownMenuRangeValues={ predefinedVolumeRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Range Clicks */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'clicks' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-clicks' }
              id="filter-range-clicks"
              pictoTitle={ getPicto( 'Mouse', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Clics"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'clicks' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'clicks' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range',
                  variation: 'variation'
                },
                'clicks',
                'gsc.clicks',
                'Clics',
                filterDatas 
              )}
              sliderMinValue={ minClicks !== null ? minClicks : 0 }
              sliderMaxValue={ maxClicks !== null ? maxClicks : 100 }
              dropDownMenuRangeValues={ predefinedClicksRanges }
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Range Position */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'position' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-position' }
              id="filter-range-position"
              pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Position"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'position' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'position' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range-position',
                  variation: 'variation',
                  movement: 'movement'
                },
                'position',
                'serp.position',
                'Position',
                filterDatas 
              )}
              sliderMinValue={ minPosition !== null ? minPosition : 0 }
              sliderMaxValue={ 101 }
              dropDownMenuRangeValues={ predefinedPositionRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
              dropDownMenuMoveValues={[
                { label: 'Entrées', value: 'in' },
                { label: 'Sorties', value: 'out' }
              ]}            
            />
            : null
          }

          {/* Range Impressions */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'impressions' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-impressions' }
              id="filter-range-impressions"
              pictoTitle={ getPicto( 'Eye', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Impressions"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'impressions' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'impressions' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range',
                  variation: 'variation'
                },
                'impressions',
                'gsc.impressions',
                'Impressions',
                filterDatas 
              )}
              sliderMinValue={ minImpressions !== null ? minImpressions : 0 }
              sliderMaxValue={ maxImpressions !== null ? maxImpressions : 100 }
              dropDownMenuRangeValues={ predefinedImpressionsRanges }
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />            
            : null
          }
          
          {/* Range CTR */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'ctr' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-ctr' }
              id="filter-range-ctr"
              pictoTitle={ getPicto( 'Cursor', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="CTR ( % )"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'ctr' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'ctr' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range-ctr',
                  variation: 'variation'
                },
                'ctr',
                'gsc.ctr',
                'CTR (%)',
                filterDatas
              )}
              sliderMinValue={ minCTR !== null ? Math.floor( minCTR ) : 0 }
              sliderMaxValue={ maxCTR !== null ? Math.ceil( maxCTR ) : 100 }
              dropDownMenuRangeValues={ predefinedCTRRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Range Tx Occupation */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'ranked-rate' ) ?
            <RangeFilterWidget 
              key={ reloadKey + '-ranked-rate' }
              id="filter-range-ranked-rate"
              pictoTitle={ getPicto( 'ArrowsOutSimple', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Tx Occupation ( % )"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'ranked-rate' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'ranked-rate' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range-ranked-rate',
                  variation: 'variation'
                },
                'ranked-rate',
                'serp.rate',
                'Tx Occupation (%)',
                filterDatas
              )}
              sliderMinValue={ minRankedRate !== null ? Math.floor( minRankedRate ) : 0 }
              sliderMaxValue={ maxRankedRate !== null ? Math.ceil( maxRankedRate ) : 100 }
              dropDownMenuRangeValues={ predefinedRankedRateRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Snippets */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'snippets' ) ?
            <SnippetsFilterWidget 
              key={ reloadKey + '-snippets' }
              id="filter-snippets"
              pictoTitle={ getPicto( 'Seal', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Snippets"    
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'snippets' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'snippets' )[0].values 
                  : null
              }  
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'snippets'
                },
                'snippets',
                'serp.snippets',
                'Snippets',
                filterDatas?.value?.filterOn ? filterDatas : null
              )}
            />
            : null
          }

          {/* Dropdown Status */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'status' ) ?
            <DropDownMenuMultiFilterWidget 
              key={ reloadKey + '-status' }
              id="filter-status"
              pictoTitle={ getPicto( 'Checks', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Statuts"
              dropDownMenuValues={ !isNullOrUndefined( userDatas?.statusKeyword ) ? 
                userDatas.statusKeyword.map( elem => ({ ...elem, value: elem.id })) 
                : [] 
              }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'status' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'status' )[0].values 
                  : null
              }  
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'status'
                },
                'status',
                'status_action',
                'Statuts',
                filterDatas
              )}
            />
            : null
          }

          {/* Keywords */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'keywords' ) ?
            <KeywordsFilterWidget 
              key={ reloadKey + '-keyword' }
              id="filter-keywords"
              pictoTitle={ getPicto( 'PenNib', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Mot-clé"
              autoCompletedValues={ autoCompletedLabelsValues }
              dropDownMenuKeywordsGroupsValues={ dropDownMenuKeywordsGroups }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'label' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'label' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'string',
                  keywordsGroups: 'keywords-groups'
                },
                'label',
                'label',
                'Mot-clé',
                filterDatas //filterDatas.value.value !== '' ? filterDatas : null 
              )}
            /> 
            : null
          }

          {/* Categories */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'categories' ) ?
            <Categories 
              key={ reloadKey + '-categories' }
              id="filter-categories"
              pictoTitle={ getPicto( 'ListChecks', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Catégories"
              categories={ categories !== null ? categories : {} }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'categories' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'categories' )[0].values 
                  : null
              }  
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'categories'
                },
                'categories',
                'categories',
                'Catégories',
                filterDatas?.value?.values !== undefined && filterDatas.value.values.length > 0 ? filterDatas : null
              )}
            />
            : null
          }
          
          {/* Url positionnée */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'ranked-url' ) ?
            <UrlFilterWidget 
              key={ reloadKey + '-ranked-url' }
              id="filter-ranked-url"
              pictoTitle={ getPicto( 'LinkSimpleHorizontal', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Url positionnée"
              autoCompletedValues={ autoCompletedRankedUrlsValues }
              removeDomain
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'ranked-url' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'ranked-url' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'string-in-array'
                },
                'ranked-url',
                'gsc.urls',
                'Url positionnée',
                filterDatas.value.value !== '' ? filterDatas : null
              )}
            />
            : null
          }

          {/* Url attendue */}
          { categories !== null && Array.isArray( filtersToDisplay ) && filtersToDisplay.includes( 'expected-url' ) ?
            <UrlFilterWidget 
              key={ reloadKey + '-expected-url' }
              id="filter-expected-url"
              pictoTitle={ getPicto( 'LinkSimple', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Url attendue"
              autoCompletedValues={ autoCompletedExpectedUrlsValues }
              removeDomain
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'expected-url' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'expected-url' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'string'
                },
                'expected-url',
                'expectedUrl',
                'Url attendue',
                filterDatas.value.value !== '' ? filterDatas : null
              )}
            />
            : null
          }

        </div>

        { categories !== null ?
          <div className='confirm-container'>
            <Button 
              className='flat fill' 
              onClick={ () => validForm() }        
            >Filtrer</Button>
            
            <Button 
              className='flat empty'
              onClick={ () => clearFilters() }
            >Effacer filtres</Button>
          </div>
          : null
        }
      </div>

      {/* Groupes de filtres */}
      <FiltersGroups
        currentFilters={ currentFilters }
        callBackFunction={ values => updateFiltersFromGroup( values ) }
      />

    </Fragment>
  )
}

export default FiltersWindow;